<template>
    <button id="excelButton" type="button" class="btn btn-success" @click="indexExcel" style="position: absolute; top: 126px; right: 55px; width: 150px; z-index: 50000;">
        {{ $t('overview.export') }}
    </button>
    <Overview 
        :moduleName="$tc('navigation.' + this.$route.name, 1)"
        :moduleNamePlural="$tc('navigation.' + this.$route.name, 2)"
        :sort="'created_at'"
        :sortOrder="-1"
        :itemService=itemService
        :itemFormRef=itemFormRef
        :pushLeft="true"
        ref="overview"
    >
        <template #columns>
            <Column field="month" :header="$t('overview.month')" style="width: 120px" :sortable="true"></Column>
            <Column field="company.number" :header="$t('overview.number')" :sortable="true" style="width: 120px"></Column>
            <!--<Column field="company.number" header="HR?" :sortable="false" style="width: 100px">
                <template #body="slotProps">
                    <i :class="'fa fa-' + (slotProps.data.contract && slotProps.data.contract.user && slotProps.data.contract.user.is_hr ? 'check' : 'none')"></i>
                </template>
            </Column>-->
            <Column field="company_name" :header="$tc('navigation.companies', 1)" :sortable="true"></Column>
            <Column field="company.debtor.customer.number" :header="$t('form.cnumber')" :sortable="true"></Column>
            <Column field="contract_amount" :header="$t('overview.contract_amount')" :sortable="true"></Column>
            <Column field="signrequest_amount" :header="$t('overview.signrequest_amount')" :sortable="true"></Column>
        </template>

        <template #form>
            <CompanyForm ref="ItemForm" :itemData="item" />
        </template>

        <template #actionscolumn><span></span></template>
    </Overview>
  <!-- <div class="footer-buttons mb-2">
    <button type="button" class="btn action-button" data-bs-toggle="modal" data-bs-target="#formModal" @click="$refs.overview.addItem()">{{ $t('form.add', [$tc('navigation.' + this.$route.name, 1)]) }}</button>
  </div> -->
    
  <button id="inv_button" type="button" class="d-none" @click="index"></button>
</template>

<script>
import store from '@/store/user'
import Overview from '@/components/Overview.vue';
import Column from 'primevue/column';
import signRequestService from '@/services/SignRequestService';
import CompanyForm from '@/components/forms/CompanyForm.vue';

export default {
    name: 'Charges',
    components: {
        Overview,
        Column,
        CompanyForm
    },
    data() {
        return {
            item: {},
            itemFormRef: null,
            itemService: signRequestService
        }
    },
    methods: {
        index(){            
            this.$refs.overview.index();
        },
        indexExcel() {
            let overviewref = this.$refs.overview;
            let dateFrom = overviewref.dateFrom ? overviewref.dateFrom : '';
            let dateTo = overviewref.dateTo ? overviewref.dateTo : '';
            signRequestService.indexExcel(dateFrom, dateTo)
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                const today = new Date();
                const dateString = today.toISOString().slice(0, 10);
                link.setAttribute('download', `${dateString}.csv`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch(error => {
                console.error('Error downloading csv:', error);
            });
        }
    },
    mounted() {
        this.itemFormRef = this.$refs.ItemForm;
        if(!store.getters.getIsHR){
            this.$router.push({
                name: "Home"
            });
        }
    }
}
</script>
import {ApiService} from '@/services/ApiService'
import http from '@/http-common'

const URL = '/api/signrequest'

class SignRequestService extends ApiService {
    constructor(){
        super()
    }
    getUrl(){
        return URL
    }
    indexExcel(from, to) {
        return http.get(`${this.getUrl()}/excel?from_date=${from}&to_date=${to}`)
    }
}

export default new SignRequestService()